export const getFirstQuestion = async (): Promise<Response> => {
  return fetch("/api/questions/first", {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getNextQuestionByAnswerId = async (
  answerId: number
): Promise<Response> => {
  return fetch(`/api/questions/${answerId}?requestType=byAnswerId`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getLastFilling = async (): Promise<Response> => {
  return fetch(`/api/fillings/last`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const sendAnswersHistory = async (
  answerId: number[],
  openAnswerText?: string
): Promise<Response> => {
  let ogj;
  if (openAnswerText) {
    ogj = { answerId, openAnswerText };
  } else {
    ogj = { answerId };
  }
  return fetch(`/api/fillings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
    body: JSON.stringify(ogj),
  });
};
