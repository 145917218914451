import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Recoil from "recoil";
import { userAtom } from "../utils/constants/recoilConstants";

// @ts-ignore
const PrivateRoute = ({ children }) => {
  const authSession = sessionStorage.getItem("authToken");
  const authAtom = Recoil.useRecoilValue(userAtom);
  const location = useLocation();
  return authAtom || authSession ? (
    children
  ) : (
    <Navigate
      to="/web/login"
      replace={true}
      state={{ from: location.pathname }}
    />
  );
};

export default PrivateRoute;
