import React from "react";
import Recoil from "recoil";
import { Link } from "react-router-dom";
import { TextElement } from "../../models/textElementModel";
import {
  downloadsListSelector,
  textsDownloadPageSelector,
} from "../../utils/constants/recoilConstants";
import { getTextString } from "../../utils/functions";
import { Download } from "../../models/downloadsModel";
import DownloadElement from "./components/DownloadElement";

const Downloads = () => {
  const texts: TextElement[] = Recoil.useRecoilValue<TextElement[]>(
    textsDownloadPageSelector
  );
  document.title = `${getTextString(texts, "title0")}: ${getTextString(
    texts,
    "title5"
  )}`;
  const downloadsList = Recoil.useRecoilValue<Download[]>(
    downloadsListSelector
  );
  const winDownloadsListHtml = downloadsList
    .filter((item: Download) => item && item.os === "win")
    .map((download: Download, index: number) => {
      return <DownloadElement key={index} downloadItem={{ download, index }} />;
    });

  const macDownloadsListHtml = downloadsList
    .filter((item: Download) => item && item.os === "mac")
    .map((download: Download, index: number) => {
      return <DownloadElement key={index} downloadItem={{ download, index }} />;
    });

  return (
    <div id="contenuto">
      <div id="monoColonna">
        <h1>{getTextString(texts, "intro1")}</h1>
        <h2>{getTextString(texts, "down1")}</h2>
        <p>
          {`${getTextString(texts, "new_down_1")} ${getTextString(
            texts,
            "new_down_3"
          )} `}
          <Link to="/web/old_downloads">
            {getTextString(texts, "new_down_2")}
          </Link>
        </p>
        <h3>{getTextString(texts, "down3")}</h3>
        {winDownloadsListHtml}
        <h3>{getTextString(texts, "new_down_16")}</h3>
        {macDownloadsListHtml}
      </div>
    </div>
  );
};

export default Downloads;
