import React from "react";
import Recoil from "recoil";
import { getTextString } from "../utils/functions";
import { TextElement } from "../models/textElementModel";
import { textsHomePageSelector } from "../utils/constants/recoilConstants";

const Header = () => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsHomePageSelector);

  return (
    <div id="intestazione">
      <div id="banner">
        <p>{getTextString(texts, "title1")}</p>
        <h1>{getTextString(texts, "header1")}</h1>
      </div>
    </div>
  );
};

export default Header;
