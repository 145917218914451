import React from "react";
import Recoil from "recoil";
import { Link, Navigate } from "react-router-dom";
import { TextElement } from "../models/textElementModel";
import { getTextString } from "../utils/functions";
import {
  hideProgressBar,
  showModalDialog,
  showProgressBar,
  validateEmail,
} from "../legacyJs/base";
import { User } from "../models/userModel";
import { registrationUser } from "../controllers/userController";
import {
  textsErrorPageSelector,
  textsRegistrationPageSelector,
  textsStandardsPageSelector,
} from "../utils/constants/recoilConstants";
import { googleCaptchaApiKey } from "../utils/constants/envConstants";

const Registration = () => {
  const texts = Recoil.useRecoilValue<TextElement[]>(
    textsRegistrationPageSelector
  );
  const errorsTexts = Recoil.useRecoilValue<TextElement[]>(
    textsErrorPageSelector
  );
  const standardsTexts = Recoil.useRecoilValue<TextElement[]>(
    textsStandardsPageSelector
  );

  const [username, setUsername] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [surname, setSurname] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [address, setAddress] = React.useState<string>("");
  const [dealer, setDealer] = React.useState<string>("");
  const [dealerCity, setDealerCity] = React.useState<string>("");
  const [captcha, setCaptcha] = React.useState<string>("");
  const [privacy, setPrivacy] = React.useState<boolean>(false);
  const [redirectToLogin, setRedirectToLogin] = React.useState<boolean>(false);

  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${googleCaptchaApiKey}`;
  document.title = `${getTextString(texts, "title0")}: ${getTextString(
    texts,
    "title6"
  )}`;

  React.useEffect(() => {
    document.body.appendChild(script);
    setTimeout(() => generateCaptcha(), 1000);
    return () => {
      const v = document.body.getElementsByClassName("grecaptcha-badge")[0];
      if (v) {
        // @ts-ignore
        v.style.display = "none";
      }
    };
  }, []);

  React.useEffect(() => {
    const handleEnterKeyPress = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        registration();
      }
    };

    document.addEventListener("keydown", handleEnterKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [
    username,
    privacy,
    captcha,
    dealerCity,
    dealer,
    address,
    city,
    surname,
    name,
    email,
  ]);

  const generateCaptcha = () => {
    // @ts-ignore
    grecaptcha.ready(() => {
      // @ts-ignore
      grecaptcha
        .execute(googleCaptchaApiKey, {
          action: "register",
        })
        .then((token: string) => {
          setCaptcha(token);
        });
    });
  };

  const registration = () => {
    let errorString: string =
      (!username ? `${getTextString(errorsTexts, "e_username")};\n` : "") +
      (!email ? `${getTextString(errorsTexts, "e_email")};\n` : "") +
      (!name ? `${getTextString(errorsTexts, "e_nome")};\n` : "") +
      (!surname ? `${getTextString(errorsTexts, "e_cognome")};\n` : "") +
      (!privacy
        ? `${getTextString(errorsTexts, "e_privacy_confirmation")};\n`
        : "") +
      (!validateEmail(email) ? getTextString(errorsTexts, "e_email_2") : "");

    if (errorString.length === 0) {
      showProgressBar(getTextString(texts, "reg19"));
      const user: User = {
        username,
        email,
        name,
        surname,
        dealerLocality: dealerCity,
        dealerName: dealer,
        residenceLocality: city,
        residentialAddress: address,
        captchaToken: captcha,
      };
      registrationUser(user)
        .then((response) => {
          switch (response.status) {
            case 201:
              return response;
            default:
              return response.json();
          }
        })
        .then((response) => {
          hideProgressBar();
          if (response.status === 201) {
            setRedirectToLogin(true);
          } else if (response.message?.includes("Username")) {
            showModalDialog(
              getTextString(errorsTexts, "error1"),
              getTextString(standardsTexts, "standard2") +
                getTextString(errorsTexts, "e_username_1"),
              "Ok"
            );
          } else if (response?.message?.includes("Email")) {
            showModalDialog(
              getTextString(errorsTexts, "error1"),
              getTextString(standardsTexts, "standard2") +
                getTextString(errorsTexts, "e_email_1"),
              "Ok"
            );
          } else {
            showModalDialog(
              getTextString(errorsTexts, "error1"),
              getTextString(errorsTexts, "error1"),
              "Ok"
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      errorString = `${getTextString(
        standardsTexts,
        "standard2"
      )}\n${errorString}`;
      showModalDialog(getTextString(errorsTexts, "error1"), errorString, "Ok");
    }
  };

  return (
    <>
      <div id="contenuto">
        <div id="monoColonna">
          <h1>{getTextString(texts, "intro1")}</h1>
          <h2>{getTextString(texts, "reg1")}</h2>
          <p>{getTextString(texts, "reg2")}</p>
          <p>{getTextString(texts, "reg3")}</p>
          <fieldset id="captcha">
            <ol>
              <li>
                <label htmlFor="loginName">
                  {`${getTextString(texts, "reg4")}:`}
                </label>
                <input
                  id="loginNameNoInvio"
                  name="loginName"
                  size={20}
                  maxLength={60}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </li>
              <li>
                <label htmlFor="email">
                  {`${getTextString(texts, "reg5")}:`}
                </label>
                <input
                  id="emailNoInvio"
                  name="email"
                  size={20}
                  maxLength={60}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </li>
              <li>
                <label htmlFor="name">
                  {`${getTextString(texts, "reg6")}:`}
                </label>
                <input
                  id="nameNoInvio"
                  name="name"
                  size={20}
                  maxLength={60}
                  onChange={(event) => setName(event.target.value)}
                />
              </li>
              <li>
                <label htmlFor="cognome">
                  {`${getTextString(texts, "reg7")}:`}
                </label>
                <input
                  id="surnameNoInvio"
                  name="cognome"
                  size={20}
                  maxLength={60}
                  onChange={(event) => setSurname(event.target.value)}
                />
              </li>
              <li>
                <label htmlFor="address">
                  {`${getTextString(texts, "reg8")}:`}
                </label>
                <input
                  id="addressNoInvio"
                  name="address"
                  size={25}
                  maxLength={60}
                  onChange={(event) => setAddress(event.target.value)}
                />
              </li>
              <li>
                <label htmlFor="city">
                  {`${getTextString(texts, "reg9")}:`}
                </label>
                <input
                  id="cityNoInvio"
                  name="city"
                  size={25}
                  maxLength={60}
                  onChange={(event) => setCity(event.target.value)}
                />
              </li>
              <li>
                <label htmlFor="dealer">
                  {`${getTextString(texts, "reg10")}:`}
                </label>
                <input
                  id="dealerNoInvio"
                  name="dealer"
                  size={20}
                  maxLength={60}
                  onChange={(event) => setDealer(event.target.value)}
                />
              </li>
              <li>
                <label htmlFor="dealerCity">
                  {`${getTextString(texts, "reg11")}:`}
                </label>
                <input
                  id="dealerCityNoInvio"
                  name="dealerCity"
                  size={20}
                  maxLength={60}
                  onChange={(event) => setDealerCity(event.target.value)}
                />
              </li>
              <li>
                <label>{`${getTextString(texts, "reg13")}:`}</label>
                <textarea
                  id="contratto"
                  name="privacy"
                  readOnly={true}
                  rows={8}
                  cols={52}
                  value={getTextString(texts, "reg14")}
                />
              </li>
              <li>
                <label htmlFor="privacy">
                  {`${getTextString(texts, "reg15")}:`}
                </label>
                <input
                  type="checkbox"
                  name="privacy"
                  id="accediConInvio"
                  onChange={(event) => setPrivacy(event.target.checked)}
                />
                {` ${getTextString(texts, "reg16")}`}
              </li>
            </ol>
            <Link to="/" className="bottone">
              {getTextString(standardsTexts, "standard1")}
            </Link>
            <Link
              to="#"
              className="bottone"
              id="accedi"
              onClick={() => {
                registration();
              }}
            >
              {getTextString(texts, "reg17")}
            </Link>
          </fieldset>
          {redirectToLogin && <Navigate to="/web/login" />}
        </div>
      </div>
    </>
  );
};

export default Registration;
