import React from "react";
import Recoil from "recoil";
import { getTextString } from "../utils/functions";
import { TextElement } from "../models/textElementModel";
import { textsHomePageSelector } from "../utils/constants/recoilConstants";

const Home = () => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsHomePageSelector);
  document.title = `${getTextString(texts, "title0")}: ${getTextString(
    texts,
    "title2"
  )}`;

  return (
    <div id="contenuto">
      <div id="colonnaSinistra">
        <h1>{getTextString(texts, "ddaws1")}</h1>
        <p>{getTextString(texts, "ddaws2")}</p>
        <p>{getTextString(texts, "ddaws3")}</p>
        <p>{getTextString(texts, "ddaws4")}</p>
        <div className="opzioni">
          <a href="/web/expert">{getTextString(texts, "ddaws5")}</a>
          <p>{getTextString(texts, "ddaws6")}</p>
          <a href="/web/bug">{getTextString(texts, "ddaws7")}</a>
          <p>{getTextString(texts, "ddaws8")}</p>
          <a href="/web/faq">{getTextString(texts, "ddaws9")}</a>
          <p>{getTextString(texts, "ddaws10")}</p>
          <a href="/web/downloads">{getTextString(texts, "ddaws11")}</a>
          <p>{getTextString(texts, "ddaws12")}</p>
        </div>
      </div>
      <div id="promo">
        <p>{getTextString(texts, "promo1")}</p>
      </div>
    </div>
  );
};

export default Home;
