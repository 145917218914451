import React from "react";
import { Link } from "react-router-dom";
import Recoil from "recoil";
import { getTextString } from "../utils/functions";
import { TextElement } from "../models/textElementModel";
import { textsHomePageSelector } from "../utils/constants/recoilConstants";
import {
  creaMeccanismoMenu,
  hideSottomenu,
  showSottomenu,
} from "../legacyJs/base";

const Menu = () => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsHomePageSelector);

  const subMenuTexts = {
    downloads: getTextString(texts, "submenu2"),
    faq: getTextString(texts, "submenu3"),
    bug: getTextString(texts, "submenu4"),
    expert: getTextString(texts, "submenu5"),
  };

  const download = getTextString(texts, "menu2");
  const faq = getTextString(texts, "menu3");
  const bug = getTextString(texts, "menu4");
  const expert = getTextString(texts, "menu5");

  React.useEffect(() => {
    creaMeccanismoMenu();
  }, []);

  return (
    <>
      <div id="fasciamenu">
        <div id="menu">
          <Link
            to="/web/downloads"
            id="menuDownload"
            onMouseEnter={() => showSottomenu("sottomenuDownload")}
            onMouseLeave={() => hideSottomenu("sottomenuDownload")}
          >
            {download}
          </Link>
          <Link
            to="/web/faq"
            id="menuFAQ"
            onMouseEnter={() => showSottomenu("sottomenuFAQ")}
            onMouseLeave={() => hideSottomenu("sottomenuFAQ")}
          >
            {faq}
          </Link>
          <Link
            to="/web/bug"
            id="menuBug"
            onMouseEnter={() => showSottomenu("sottomenuBug")}
            onMouseLeave={() => hideSottomenu("sottomenuBug")}
          >
            {bug}
          </Link>
          <Link
            to="/web/expert"
            id="menuEsperto"
            onMouseEnter={() => showSottomenu("sottomenuEsperto")}
            onMouseLeave={() => hideSottomenu("sottomenuEsperto")}
          >
            {expert}
          </Link>
          <Link to="/web" className="goHome">
            {" "}
          </Link>
        </div>
      </div>
      <div id="contenitoreSottomenu">
        <div
          className="sottomenu"
          id="sottomenuEsperto"
          onMouseEnter={() => showSottomenu("sottomenuEsperto")}
          onMouseLeave={() => hideSottomenu("sottomenuEsperto")}
        >
          <Link to="/web/expert">{subMenuTexts.expert}</Link>
        </div>
        <div
          className="sottomenu"
          id="sottomenuDownload"
          onMouseEnter={() => showSottomenu("sottomenuDownload")}
          onMouseLeave={() => hideSottomenu("sottomenuDownload")}
        >
          <Link to="/web/downloads">{subMenuTexts.downloads}</Link>
        </div>
        <div
          className="sottomenu"
          id="sottomenuFAQ"
          onMouseEnter={() => showSottomenu("sottomenuFAQ")}
          onMouseLeave={() => hideSottomenu("sottomenuFAQ")}
        >
          <Link to="/web/faq">{subMenuTexts.faq}</Link>
        </div>
        <div
          className="sottomenu"
          id="sottomenuBug"
          onMouseEnter={() => showSottomenu("sottomenuBug")}
          onMouseLeave={() => hideSottomenu("sottomenuBug")}
        >
          <Link to="/web/bug">{subMenuTexts.bug}</Link>
        </div>
      </div>
    </>
  );
};

export default Menu;
