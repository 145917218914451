import React from "react";
import Recoil from "recoil";
import { formatBytes, getTextString } from "../../../utils/functions";
import { TextElement } from "../../../models/textElementModel";
import { textsDownloadPageSelector } from "../../../utils/constants/recoilConstants";
import { Download } from "../../../models/downloadsModel";
import { downloadFileFromURL } from "../../../controllers/downloadsControllers";

const DownloadElement = (props: {
  downloadItem: { download: Download; index: number };
}) => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsDownloadPageSelector);
  const { download, index } = props.downloadItem;

  const getLink = (): JSX.Element => {
    if (download.os === "win") {
      if (download.downloadType === "dda-software") {
        return (
          <a
            href="#"
            onClick={async () => {
              await downloadFileFromURL(
                `/api/downloads/${download.id}`,
                download
              );
            }}
            id="download_resource"
          >{`${getTextString(texts, "new_down_4")} (${
            download.releaseVersion
          }) ${getTextString(
            texts,
            download.arch.includes("32") ? "new_down_9" : "new_down_8"
          )}`}</a>
        );
      }
      return (
        <a
          href="#"
          onClick={async () => {
            await downloadFileFromURL(
              `/api/downloads/${download.id}`,
              download
            );
          }}
          id="download_resource"
        >{`${getTextString(texts, "new_down_19")} (${
          download.releaseVersion
        })`}</a>
      );
    }
    return (
      <a
        href="#"
        onClick={async () => {
          await downloadFileFromURL(`/api/downloads/${download.id}`, download);
        }}
        id="download_resource"
      >{`${getTextString(texts, "new_down_4")} (${
        download.releaseVersion
      }) ${getTextString(
        texts,
        download.arch.includes("32") ? "new_down_5" : "new_down_6"
      )}`}</a>
    );
  };

  const getDescription = () => {
    if (download.os === "win") {
      if (download.downloadType === "dda-software") {
        return `${getTextString(texts, "new_down_10")} ${getTextString(
          texts,
          download.arch.includes("32") ? "new_down_11" : "new_down_13"
        )}`;
      }
      return `${getTextString(texts, "new_down_20")} ${getTextString(
        texts,
        "new_down_11"
      )}`;
    }
    return `${getTextString(texts, "new_down_10")} ${getTextString(
      texts,
      download.arch.includes("32") ? "new_down_16" : "new_down_15"
    )}`;
  };

  return (
    <dl key={index}>
      <dt>{`${getTextString(texts, "down4")}:`}</dt>
      <dd className="id">{getLink()}</dd>
      <dt>{`${getTextString(texts, "down5")}:`}</dt>
      <dd>{download.size ? formatBytes(Number(download.size)) : "Unknown"}</dd>
      <dt>{`${getTextString(texts, "down6")}:`}</dt>
      <dd dangerouslySetInnerHTML={{ __html: getDescription() }} />
    </dl>
  );
};

export default DownloadElement;
