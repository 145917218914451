import React from "react";
import Recoil from "recoil";
import { formatBytes, getTextString } from "../../../utils/functions";
import { OldDownload } from "../../../models/downloadsModel";
import { TextElement } from "../../../models/textElementModel";
import { textsDownloadPageSelector } from "../../../utils/constants/recoilConstants";
import { downloadFileFromURL } from "../../../controllers/downloadsControllers";

const OldDownloadElement = (props: {
  downloadItem: { download: OldDownload; index: number };
}) => {
  const { download, index } = props.downloadItem;
  const texts = Recoil.useRecoilValue<TextElement[]>(textsDownloadPageSelector);
  return (
    <dl key={index}>
      <dt>{`${getTextString(texts, "down4")}:`}</dt>
      <dd className="id">
        <a
          href="#"
          onClick={async () => {
            await downloadFileFromURL(
              `/api/old_downloads/${download.id}`,
              download
            );
          }}
          className="id"
          id={download.id.toString()}
        >
          {download.link}
        </a>
      </dd>
      <dt>{`${getTextString(texts, "down5")}:`}</dt>
      <dd>{download.size ? formatBytes(Number(download.size)) : "Unknown"}</dd>
      <dt>{`${getTextString(texts, "down6")}:`}</dt>
      <dd dangerouslySetInnerHTML={{ __html: download.description }} />
    </dl>
  );
};

export default OldDownloadElement;
