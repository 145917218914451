// base.js  version for www.prosa.com
// last revised feb 29 2008

// Questo insieme di script è utile per le seguenti cose:
//
// 1. Automatizzare il processo di submit di una form
// --------------------------------------------------
// A tal scopo bisogna:
//   1.1. prevedere, nelle form di una pagina, degli input di type submit
//        a cui associare, all'evento onclick, uno script complesso a piacere,
//        ma che si concluda con l'invocazione della funzione:
//
//               export function submitForm( nomeForm, messaggioAttesa )
//
//        la quale visualizza una progress-bar con il messaggioAttesa mentre
//        opera il submit della form.
//        N.B. in caso di disattivazione di javascript, il submit delle form
//        continua a funzionare grazie all'utilizzo degli input standard di tipo
//        submit, ma tutti gli eventuali controlli fatti dallo script associato
//        all'input non vengono eseguiti: di questo dev'essere tenuto conto
//        nell'URL richiamato.
//
//   1.2. fornire ai campi input di testo che acquisiscono i dati di input della
//        form, un id che abbia un nome che contenga la stringa
//
//               <id-inputSubmit>+"ConInvio"
//
//        dove <id-inputSubmit> è il nome dell'id del campo input di tipo
//        submit di cui al punto 1.1 sopra.
//        In questo modo si crea automaticamente l'associazione tra lo script
//        e la pressione di "invio" da tastiera sul campo di input testuale.
//
//
// 2. Visualizzare dialoghi modali
// -------------------------------
// Usando la funzione
//
//              export function showModalDialog( titolo, messaggio, etichettaBottone )
//
//
// 3. Visualizzare progress bar
// ----------------------------
// Usando la funzione
//
//              export function showProgressBar( testoDiAttesa )
//
//
// -----------------------------------------------------------------------------
//    ATTENZIONE: non è necessario prevedere nel layout della pagina nulla di
//    aggiuntivo affinché il funzionamento delle cose sopra sia operato.
//    Tutto viene automatizzato dall'unica funzione che dev'essere chiamata
//    nella pagina nel seguente modo:
//
//              <body onload="completaLayout()">
//
/* eslint-disable */
export function getElemento(idElemento) {
  if (document.getElementById) {
    return document.getElementById(idElemento);
  }
  return eval(idElemento);
}

export function getKeyPress(e) {
  let code;
  if (!e) var e = window.event;
  if (e.keyCode) code = e.keyCode;
  else if (e.which) code = e.which;
  return code;
}

// determina le dimensioni effettive della finestra del browser

export function getBrowserWidth() {
  let browserWidth;
  browserWidth = 0;
  if (typeof window.innerWidth === "number") {
    // Non-IE
    browserWidth = window.innerWidth;
  } else if (
    document.documentElement &&
    (document.documentElement.clientWidth ||
      document.documentElement.clientHeight)
  ) {
    // IE 6+ in 'standards compliant mode'
    browserWidth = document.documentElement.clientWidth;
  } else if (
    document.body &&
    (document.body.clientWidth || document.body.clientHeight)
  ) {
    // IE 4 compatible
    browserWidth = document.body.clientWidth;
  }
  return browserWidth;
}

export function getBrowserHeight() {
  let browserHeight;
  browserHeight = 0;
  if (typeof window.innerWidth === "number") {
    // Non-IE
    browserHeight = window.innerHeight;
  } else if (
    document.documentElement &&
    (document.documentElement.clientWidth ||
      document.documentElement.clientHeight)
  ) {
    // IE 6+ in 'standards compliant mode'
    browserHeight = document.documentElement.clientHeight;
  } else if (
    document.body &&
    (document.body.clientWidth || document.body.clientHeight)
  ) {
    // IE 4 compatible
    browserHeight = document.body.clientHeight;
  }
  return browserHeight;
}

// trova la posizione dell'oggetto all'interno della pagina

export function findPosX(obj) {
  let curleft = 0;
  if (obj.offsetParent) {
    while (obj.offsetParent) {
      curleft += obj.offsetLeft;
      obj = obj.offsetParent;
    }
  } else if (obj.x) curleft += obj.x;
  return curleft;
}

export function findPosY(obj) {
  let curtop = 0;
  if (obj.offsetParent) {
    while (obj.offsetParent) {
      curtop += obj.offsetTop;
      obj = obj.offsetParent;
    }
  } else if (obj.y) curtop += obj.y;
  return curtop;
}

// commuta la visibilità

export function mostraNascondi(idElemento) {
  // mostra o nasconde lasciando lo spazio
  const elemento = getElemento(idElemento);
  if (elemento.style.visibility != "visible") {
    elemento.style.visibility = "visible";
  } else {
    elemento.style.visibility = "hidden";
  }
}

export function mostraCela(idElemento) {
  // mostra o nasconde togliendo lo spazio
  const elemento = getElemento(idElemento);
  if (elemento.style.display == "none") {
    elemento.style.display = "";
  } else {
    elemento.style.display = "none";
  }
}

export function selezionaDeseleziona(idElemento) {
  const elemento = getElemento(idElemento);
  if (elemento.className == "voceselez") {
    elemento.className = "vocedeselez";
  } else {
    elemento.className = "voceselez";
  }
}

// gestione sottomenu

export function showSottomenu(idMenu) {
  // a causa di un eventuale ridimensionamento della finestra del browser
  // va ricalcolata la posizione del contenitore dei sotto-menu
  let menuBar;
  menuBar = getElemento("menu");
  riposizionaContenitoreSottomenu(menuBar);

  const submenu = getElemento(idMenu);
  submenu.style.display = "block";
}

export function hideSottomenu(idMenu) {
  const submenu = getElemento(idMenu);
  submenu.style.display = "none";
}

export function riposizionaContenitoreSottomenu(menuBar) {
  const contenitoreSottomenu = getElemento("contenitoreSottomenu");

  // posizionamento assoluto del contenitore dei sottomenu rispetto
  // al menu 'menuBar' (senza javascript rimarrebbe posizionato sempre in
  // assoluto, ma in percentuale della larghezza schermo, dunque non
  // precisamente sotto il menu 'menuBar')
  contenitoreSottomenu.style.left = `${
    menuBar.offsetLeft + menuBar.offsetWidth - contenitoreSottomenu.offsetWidth
  }px`;
  contenitoreSottomenu.style.top = `${menuBar.offsetHeight - 4}px`;
  // '-4' affinché i sottomenu si sovrappongano leggermente al menu,
  // così da far transitare il 'mousover' dal menu al sottomenu

  return contenitoreSottomenu;
}

export function creaMeccanismoMenu(){
  menubar = getElemento( 'menu' );
  var contenitoreSottomenu = riposizionaContenitoreSottomenu( menubar );

  // posizionamento di tutti i sottomenu in modo assoluto senza alcuna
  // specifica di coordinate (vanno dunque tutti in alto a sinistra del
  // contenitore dei sottomenu).
  // Tutti i sottomenu vanno poi nascosti (vengono visualizzati solo con
  // l'hover dei relativi menu).
  // Ogni sottomenu, inoltre, va equipaggiato con il corredo javascrpt per
  // nasconderlo e visualizzarlo coerentemente al menu.
  var elencoSottomenu = contenitoreSottomenu.getElementsByTagName( "div" );
  if ( elencoSottomenu.length < 1 ) return false;

  var nomeFunzione;
  for (var i=0; i < elencoSottomenu.length; i++) {
    if ( elencoSottomenu[i].className.indexOf( "sottomenu" ) != -1 ) {
      elencoSottomenu[i].style.position = "absolute";

      // la seguente istruzione sarebbe inutile, se IE6 e IE7
      // funzionassero correttamente
      elencoSottomenu[i].style.left = '0px';

      elencoSottomenu[i].style.display = "none";

      // assegnazione delle necessarie funzioni per la visualizzazione
      // del sottomenu in mutua esclusione
      // nomeFunzione = "showSottomenu('" + elencoSottomenu[i].id + "')";
      // elencoSottomenu[i].onmouseover = new Function(nomeFunzione);
      // nomeFunzione = "hideSottomenu('" + elencoSottomenu[i].id + "')";
      // elencoSottomenu[i].onmouseout = new Function(nomeFunzione);
    }
  }

  // Assegnazione a ciascun menu delle opportune funzionalità per far
  // comparire e scomparire i relativi sottomenu.
  // N.B. E' necessario che il nome dell'ID del sottomenu da correlare a un
  // menu sia della forma 'sotto<nome menu>'.
  var elencoMenu = menubar.getElementsByTagName( "a" );
  if ( elencoMenu.length < 1 ) return false;

  // for (var i=0; i < elencoMenu.length; i++) {
  //   if ( elencoMenu[i].className.indexOf( "goHome" ) != -1 ) continue;
  //   nomeFunzione = "showSottomenu('sotto" + elencoMenu[i].id + "')";
  //   elencoMenu[i].onmouseover = new Function(nomeFunzione);
  //   nomeFunzione = "hideSottomenu('sotto" + elencoMenu[i].id + "')";
  //   elencoMenu[i].onmouseout = new Function(nomeFunzione);
  // }
}

export function hideModalDialog() {
  let dialogoElement;
  dialogoElement = getElemento("dialogoModale");
  dialogoElement.style.display = "none";

  return false;
}

export function showModalDialog(titolo, messaggio, etichettaBottone) {
  let dialogoElement = getElemento("dialogoModale");
  if (dialogoElement != null) {
    var titoloOutput = getElemento("titoloDialogo");
    var testoOutput = getElemento("testoDialogo");
    var finestra = getElemento("finestraDialogo");
    titoloOutput.lastChild.nodeValue = titolo;
    testoOutput.lastChild.nodeValue = messaggio;
    const elencoBottoni = finestra.getElementsByTagName("a");
    elencoBottoni[0].lastChild.nodeValue = etichettaBottone;
    dialogoElement.style.display = "inline";
  } else {
    // se il dialogo non esiste ancora, va creato il div opportuno con
    // tutti i div necessari per il velo, la finestra, ecc.
    // La forma finale del div dev'essere:
    // ----------------------------------------------------------------------------
    //    <div id="dialogoModale">
    //      <div id="velo"></div>
    //      <div id="finestraDialogo">
    //        <div id="titoloDialogo">titolo</div>
    //        <div id="testoDialogo">messaggio</div>
    //        <a class="bottone" href="#" onClick="hideModalDialog(); return false;">etichettaBottone</a>
    //      </div>
    //    </div>
    // ----------------------------------------------------------------------------
    var titoloOutput = document.createElement("div");
    titoloOutput.setAttribute("id", "titoloDialogo");
    const testoTitolo = document.createTextNode(titolo);
    titoloOutput.appendChild(testoTitolo); // <div id="titoloDialogo">titolo</div>  -- fatto!

    var testoOutput = document.createElement("div");
    testoOutput.setAttribute("id", "testoDialogo");
    const testoMessaggio = document.createTextNode(messaggio);
    testoOutput.appendChild(testoMessaggio); // <div id="testoDialogo">messaggio</div>  -- fatto!

    const bottoneChiudi = document.createElement("a");
    bottoneChiudi.className = "bottone";
    bottoneChiudi.setAttribute("href", "#");
    bottoneChiudi.onclick = () => {
      hideModalDialog();
      return false;
    }
    const testoBottone = document.createTextNode(etichettaBottone);
    bottoneChiudi.appendChild(testoBottone); // <a class="bottone" href="#" onClick="hideModalDialog(); return false;">etichettaBottone</a>  -- fatto!

    var finestra = document.createElement("div");
    finestra.setAttribute("id", "finestraDialogo");
    finestra.appendChild(titoloOutput);
    finestra.appendChild(testoOutput);
    finestra.appendChild(bottoneChiudi); // finestraDialogo -- fatto!

    const veloElement = document.createElement("div");
    veloElement.setAttribute("id", "velo");
    dialogoElement = document.createElement("div");
    dialogoElement.setAttribute("id", "dialogoModale");
    dialogoElement.appendChild(veloElement);
    dialogoElement.appendChild(finestra); // dialogoModale -- fatto!

    // Inserimento strutturale nel body
    const corpoPagina = getElemento("pagina");
    corpoPagina.parentNode.insertBefore(dialogoElement, null);

    // Posizionamento spaziale:
    const veloPos = findPosY(veloElement);
    const browserHeight = getBrowserHeight();
    if (browserHeight > veloPos) {
      veloElement.style.height = `${browserHeight}px`;
    } else {
      veloElement.style.height = `${veloPos}px`;
    }
    veloElement.style.width = `${getBrowserWidth()}px`;
    veloElement.style.top = 0;
    veloElement.style.left = 0;

    dialogoElement.style.display = "inline";

    finestra.style.top = `${getBrowserHeight() / 2 - finestra.offsetHeight}px`;
    finestra.style.left = `${
      getBrowserWidth() / 2 - finestra.offsetWidth / 2
    }px`;
  }

  return false;
}


export function showProgressBar(testoDiAttesa) {
  let progBar = getElemento("barraProgresso");
  if (progBar != null) {
    progBar.lastChild.nodeValue = testoDiAttesa;
  }
  // altrimenti va creato il div opportuno all'interno del body
  else progBar = creaProgressBar(testoDiAttesa);

  progBar.style.display = "inline";
  progBar.style.top = `${getBrowserHeight() / 2 - progBar.offsetHeight}px`;
  progBar.style.left = `${getBrowserWidth() / 2 - progBar.offsetWidth / 2}px`;
}

export function hideProgressBar() {
  const progBar = getElemento("barraProgresso");
  progBar.style.display = "none";

  return false;
}

export function submitForm(nomeForm, messaggioAttesa) {
  showProgressBar(messaggioAttesa);
  getElemento(nomeForm).submit();
  return false;
}

// gestione avvio logon.
// Scrivere qui tutta la logica di logon.

export function avviaLogon(
  nomeForm,
  nomeLogin,
  stringaPassword,
  messaggioAttesa,
  titoloDialogo,
  messaggioDialogo,
  etichettaBottoneDialogo
) {
  const stringaLogin = getElemento(nomeLogin);
  const stringaPwd = getElemento(stringaPassword);
  if (stringaLogin.value.length < 2 || stringaPwd.value.length < 2) {
    showModalDialog(titoloDialogo, messaggioDialogo, etichettaBottoneDialogo);
    return false;
  }
  showProgressBar(messaggioAttesa);
  return true;
  //return submitForm(nomeForm, messaggioAttesa);
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function avviaRecovery(
  nomeForm,
  email,
  messaggioAttesa,
  titoloDialogo,
  messaggioDialogo,
  etichettaBottoneDialogo
) {
  if (!validateEmail(getElemento(email).value)) {
    showModalDialog(titoloDialogo, messaggioDialogo, etichettaBottoneDialogo);
    return false;
  }
  //return submitForm(nomeForm, messaggioAttesa);
}

// script per generare automaticamente, per ciascuna img la cui classe
// ha un nome che contiene il termine "figura", una vera e propria figura con
// didascalia incorporata (il testo della didascalia è quello
// dell'attributo 'alt')

export function creaFigure() {
  let figuraFlottante = true;

  if (!document.getElementsByTagName) return false;
  if (!document.createElement) return false;

  const elencoImmagini = document.getElementsByTagName("img");
  if (elencoImmagini.length < 1) return false;

  for (let i = 0; i < elencoImmagini.length; i++) {
    if (elencoImmagini[i].className.indexOf("figura") != -1) {
      let elemImmagine;
      elemImmagine = elencoImmagini[i];

      figuraFlottante = elemImmagine.className.indexOf("figuraFlottante") != -1;
      let conDidascalia;
      conDidascalia = elemImmagine.alt.length >= 1;

      if (conDidascalia) {
        var elemDidascalia = document.createElement("div");
        elemDidascalia.className = "didascalia";
        const testoDidascalia = document.createTextNode(elemImmagine.alt);
        elemDidascalia.appendChild(testoDidascalia);
      }

      const contenitoreImmagine = document.createElement("div");
      if (figuraFlottante) {
        contenitoreImmagine.className = "riquadroFiguraFlottante";
        contenitoreImmagine.style.width = `${elemImmagine.width}px`;
      } else contenitoreImmagine.className = "riquadroFiguraFissa";

      elemImmagine.parentNode.insertBefore(contenitoreImmagine, elemImmagine);
      contenitoreImmagine.appendChild(elemImmagine);

      if (conDidascalia) {
        if (figuraFlottante)
          elemDidascalia.style.width = `${elemImmagine.width}px`;
        contenitoreImmagine.appendChild(elemDidascalia);
      }
    }
  }
  return true;
}

export function creaProgressBar(testoDiAttesa) {
  const corpoPagina = getElemento("pagina");
  const progBar = document.createElement("div");
  progBar.setAttribute("id", "barraProgresso");
  const testoAttesa = document.createTextNode(testoDiAttesa);
  progBar.appendChild(testoAttesa);
  corpoPagina.parentNode.insertBefore(progBar, null);
  return progBar;
}

// script per associare a opportuni campi di input, il cui id sia riconducibile
// all'id di un bottone submit, lo stesso comportamento relativamente al tasto
// "invio" della tastiera, e dissociare da qualsiasi comportamento su invio
// altri campi.
// Detto <id-inputSubmit> l'id del bottone (cioè INPUT di type="submit"), i
// campi di input a cui viene associato il medesimo script in caso di "invio"
// avranno l'id = <id-inputSubmit>+"ConInvio".
// In questo modo il comportamento associato al submit vale in automatico
// anche su invio su alcuni campi prefissati.
// Al tempo stesso, a tutti i campi di input il cui id contiene la stringa
// "NoInvio" viene associato uno script che non fa nulla (anzi, all'invio da
// tastiera sposta il focus al campo successivo, come un TAB).
//
// IMPORTANTE: l'URL richiamato dai bottoni NON dovrà avere come pre-condizioni
// gli eventuali controlli fatti dalle funzioni javascript legate al bottone
// 'ConJS', altrimenti il funzionamento di tale URL in mancanza di javascript
// potrebbe essere compromesso.

export function attivaFormConJS() {
  const elencoForm = document.getElementsByTagName("form");
  if (elencoForm.length < 1) return false;

  const pressioneInvio = "if (getKeyPress(event) == 13) ";
  let azioneSuInvio = "return false;";

  for (let i = 0; i < elencoForm.length; i++) {
    const elencoInput = elencoForm[i].getElementsByTagName("input");
    for (var j = 0; j < elencoInput.length; j++) {
      if (elencoInput[j].type == "submit") {
        const idDelCampoAssosiato = `${elencoInput[j].id}ConInvio`;
        // Per ciascun INPUT di type="submit" viene creato un <a> con il
        // medesimo aspetto e comportamento
        const bottoneSubmit = document.createElement("a");
        bottoneSubmit.className = elencoInput[j].className;
        bottoneSubmit.setAttribute("href", "#");
        bottoneSubmit.onclick = elencoInput[j].onclick;
        const testoBottone = document.createTextNode(elencoInput[j].value);
        bottoneSubmit.appendChild(testoBottone);
        elencoInput[j].parentNode.insertBefore(bottoneSubmit, elencoInput[j]);
        // Viene cercato uno o più campi a cui associare il medesimo script
        // appena associato al nuovo bottone di input, e l'associazione al
        // campo vale per la pressione del tasto "invio" sulla tastiera
        for (let k = 0; k < elencoInput.length; k++) {
          if (elencoInput[k].id.indexOf(idDelCampoAssosiato) == -1) continue;
          // se l'ID del campo contiene la sottostringa <id-inputSubmit>+"ConInvio"
          let corpoFunzione = `${bottoneSubmit.onclick.valueOf()}`;
          corpoFunzione =
            pressioneInvio +
            corpoFunzione.substring(corpoFunzione.indexOf("{"));
          elencoInput[k].onkeypress = new Function("event", corpoFunzione);
        }
      } else if (elencoInput[j].id.indexOf("NoInvio") != -1) {
        // simula l'invio di un TAB al posto dell'invio portando il focus sul
        // successivo, se c'è, campo di input, che può essere anche un bottone
        if (j + 1 < elencoInput.length) {
          azioneSuInvio = `{getElemento( '${
            elencoInput[j + 1].id
          }' ).focus(); return false;}`;
        }
        elencoInput[j].onkeypress = new Function(
          "event",
          pressioneInvio + azioneSuInvio
        );
      }
    }
    // Poi si rimuovono tutti i bottoni prima sostituiti con <a>
    for (var j = 0; j < elencoInput.length; j++) {
      if (elencoInput[j].type == "submit") {
        elencoInput[j].parentNode.removeChild(elencoInput[j]);
        // siccome l'eliminazione dal DOM comporta anche l'eliminazione
        // dall'array che si sta scorrendo... (cosa un po' strana)...
        // bisogna tornare indietro di una posizione
        j--;
      }
    }
  }
}

// script necessario al completamento del layout. Tale pratica è condotta
// per due scopi: 1. consentire alla pagina di funzionare anche in assenza
// di attivazione di javascript; 2. mantenere la pagina estremamente
// leggera, demandando a questo script il compito di introdurre tutti
// quegli aspetti di layout e di rendering automatizzabili.
// In particolare, questo script prepara il meccanismo alla base dei menu,
// confeziona tutte le immagini di tipo "figura[..]" in modo che vengano
// collegate alle loro didascalie e collega i campi delle form agli script
// associato ai submit che s'intende legare al campo con l'"invio".
// Come ultima cosa viene invocata una funzione necessaria per pre-caricare
// l'immagine utile alla prgress-bar, progress-bar aggiunta dinamicamente
// alla pagina.

export function completaLayout() {
  showProgressBar("Loading page...");
  creaMeccanismoMenu();
  creaFigure();
  attivaFormConJS();
  window.onunload = hideProgressBar;
  hideProgressBar();
}
