import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const Page = () => {
  return (
    <div id="pagina">
      <Outlet />
      <Footer />
      <div />
    </div>
  );
};

export default Page;
