import React from "react";
import Recoil from "recoil";
import { getTextString } from "../utils/functions";
import { TextElement } from "../models/textElementModel";
import { textsHomePageSelector } from "../utils/constants/recoilConstants";

const Footer = () => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsHomePageSelector);
  const footerText: string = getTextString(texts, "footer1");

  return (
    <div id="piedipagina">
      <p> </p>
    </div>
  );
};

export default Footer;
