import { TextElement } from "../models/textElementModel";
import { userLanguage } from "./constants/constants";

/**
 * Returns the string relative string called @name in the @texts array
 * */
export const getTextString = (texts: TextElement[], name: string): string => {
  const text: TextElement | undefined = texts.find(
    (value: TextElement) => value.name === name
  );
  if (text === undefined) {
    if (userLanguage === "text_en") {
      return "String not found";
    }
    return "Stringa non trovata";
  }
  return text.text;
};

/**
 * Converts a number in bytes to the correct string size
 * */
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["KB", "MB", "GB"];
  const dm = decimals < 0 ? 0 : decimals;
  if (bytes >= k) {
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i - 1]}`;
  }
  return `${parseFloat((bytes / k).toFixed(dm))} ${sizes[0]}`;
};
