import React from "react";
import Recoil from "recoil";
import { TextElement } from "../../../models/textElementModel";
import { textsExpertPageSelector } from "../../../utils/constants/recoilConstants";
import { getTextString } from "../../../utils/functions";
import { Question } from "../../../models/questionModel";

const ExpertTitle = (props: { currentQuestion: Question }) => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsExpertPageSelector);
  const { currentQuestion } = props;
  const getExpertTitle = () => {
    if (currentQuestion.conclusive && currentQuestion.compResult) {
      return (
        <>
          <h2>{getTextString(texts, "se1")}</h2>
          <p>{getTextString(texts, "se2")}</p>
        </>
      );
    }
    if (currentQuestion.conclusive && !currentQuestion.compResult) {
      return (
        <>
          <h2>{getTextString(texts, "se3")}</h2>
          <p>{getTextString(texts, "se4")}</p>
        </>
      );
    }
    return (
      <>
        <h2>{getTextString(texts, "se5")}</h2>
        <p>{getTextString(texts, "se6")}</p>
        <p>{getTextString(texts, "se7")}</p>
      </>
    );
  };
  return <>{getExpertTitle()}</>;
};

export default ExpertTitle;
