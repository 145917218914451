import React from "react";
import Recoil from "recoil";
import { Link, Navigate } from "react-router-dom";
import { TextElement } from "../models/textElementModel";
import {
  textsPassRecoveryPageSelector,
  textsStandardsPageSelector,
} from "../utils/constants/recoilConstants";
import { getTextString } from "../utils/functions";
import {
  hideProgressBar,
  showModalDialog,
  showProgressBar,
  validateEmail,
} from "../legacyJs/base";
import { passRecovery } from "../controllers/userController";

const PassRecovery = () => {
  const texts = Recoil.useRecoilValue<TextElement[]>(
    textsPassRecoveryPageSelector
  );
  const standardsTexts = Recoil.useRecoilValue<TextElement[]>(
    textsStandardsPageSelector
  );

  const [email, setEmail] = React.useState<string>("");
  const [redirectToLogin, setRedirectToLogin] = React.useState<boolean>(false);

  const recovery = () => {
    showProgressBar(getTextString(texts, "rec5"));
    if (!validateEmail(email)) {
      hideProgressBar();
      showModalDialog(
        getTextString(texts, "rec6"),
        getTextString(texts, "rec7"),
        "OK"
      );
    } else {
      passRecovery(email)
        .then((response) => {
          switch (response.status) {
            case 200:
              return response;
            default:
              return undefined;
          }
        })
        .then((response) => {
          hideProgressBar();
          if (response) {
            showModalDialog(
              getTextString(texts, "title10"),
              getTextString(texts, "rec1").replace("%s", email),
              "OK"
            );
            setRedirectToLogin(true);
          } else {
            showModalDialog(
              getTextString(texts, "rec6"),
              getTextString(texts, "rec0"),
              "OK"
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  React.useEffect(() => {
    const handleEnterKeyPress = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        recovery();
      }
    };

    document.addEventListener("keydown", handleEnterKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [email]);

  return (
    <div id="contenuto">
      <div id="monoColonna">
        <h1>{getTextString(texts, "intro1")}</h1>
        <h2>{getTextString(texts, "intro2")}</h2>
        <p>{getTextString(texts, "rec2")}</p>
        <fieldset>
          <ol>
            <li>
              <label htmlFor="email">{getTextString(texts, "rec3")}</label>
              <input
                id="richiediConInvio"
                name="email"
                size={20}
                maxLength={60}
                onChange={(event) => setEmail(event.target.value)}
              />
            </li>
          </ol>
          <Link to="/" className="bottone">
            {getTextString(standardsTexts, "standard1")}
          </Link>
          <Link
            to="#"
            className="bottone"
            id="richiedi"
            onClick={() => recovery()}
          >
            {getTextString(texts, "rec4")}
          </Link>
        </fieldset>
      </div>
      {redirectToLogin && <Navigate to="/web/login" />}
    </div>
  );
};
export default PassRecovery;
