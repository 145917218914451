export const getRegistrationText = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=registration");
};

export const getLoginText = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=login");
};

export const getHomeTexts = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=index");
};

export const getBugText = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=bug");
};

export const getFaqText = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=faq");
};

export const getErrorText = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=error");
};

export const getStandardText = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=standard");
};

export const getPassRecoveryText = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=pass_recovery");
};

export const getDownloadsText = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=download");
};

export const getOldDownloadsText = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=old_download");
};

export const getExpertText = async (): Promise<Response> => {
  return fetch("/api/texts?pageRequested=esperto");
};
