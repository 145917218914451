import React from "react";
import Recoil from "recoil";
import { Link } from "react-router-dom";
import { TextElement } from "../../../models/textElementModel";
import { textsExpertPageSelector } from "../../../utils/constants/recoilConstants";
import { getTextString } from "../../../utils/functions";

const OpenAnswer = (props: { sendHistory: (openAnswer?: string) => void }) => {
  const { sendHistory } = props;
  const texts = Recoil.useRecoilValue<TextElement[]>(textsExpertPageSelector);
  const [text, setText] = React.useState<string>();
  return (
    <>
      <textarea
        id="sendingText"
        rows={14}
        cols={72}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
      <p>{getTextString(texts, "se11")}</p>
      <Link
        to="#"
        className="bottone"
        id="accedi"
        onClick={() => {
          sendHistory(text);
        }}
      >
        {getTextString(texts, "se12")}
      </Link>
    </>
  );
};

export default OpenAnswer;
