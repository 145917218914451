import React from "react";
import Recoil from "recoil";
import { TextElement } from "../../models/textElementModel";
import {
  oldDownloadsListSelector,
  textsOldDownloadPageSelector,
} from "../../utils/constants/recoilConstants";
import { getTextString } from "../../utils/functions";
import { OldDownload } from "../../models/downloadsModel";
import OldDownloadElement from "./components/OldDownloadElement";

const OldDownloads = () => {
  const texts: TextElement[] = Recoil.useRecoilValue<TextElement[]>(
    textsOldDownloadPageSelector
  );
  document.title = `${getTextString(texts, "title0")}: ${getTextString(
    texts,
    "title5"
  )}`;
  const oldDownloadsList: OldDownload[] = Recoil.useRecoilValue<OldDownload[]>(
    oldDownloadsListSelector
  );

  const orderedOldDownloadsList: OldDownload[] = [...oldDownloadsList];

  const softwareDownloads: OldDownload[] = orderedOldDownloadsList
    .filter((val: OldDownload) => val.type === "software")
    .sort((elem1: OldDownload, elem2: OldDownload) => {
      if (elem1.id > elem2.id) {
        return -1;
      }
      if (elem1.id < elem2.id) {
        return 1;
      }
      return 0;
    });

  const firmwareDownloads: OldDownload[] = orderedOldDownloadsList.filter(
    (val: OldDownload) => val.type === "firmware"
  );

  const genericDownloads: OldDownload[] = orderedOldDownloadsList
    .filter((val: OldDownload) => val.type === "generic")
    .sort((elem1: OldDownload, elem2: OldDownload) => {
      if (elem1.id > elem2.id) {
        return -1;
      }
      if (elem1.id < elem2.id) {
        return 1;
      }
      return 0;
    });

  return (
    <div id="contenuto">
      <div id="monoColonna">
        <h1>{getTextString(texts, "intro1")}</h1>
        <h2>{getTextString(texts, "down1")}</h2>
        <p>{getTextString(texts, "down2")}</p>
        <h3>{getTextString(texts, "down3")}</h3>
        {softwareDownloads.map((download: OldDownload, index: number) => {
          return (
            <OldDownloadElement
              key={index}
              downloadItem={{ download, index }}
            />
          );
        })}
        <h3>{getTextString(texts, "down7")}</h3>
        {firmwareDownloads.map((download: OldDownload, index: number) => {
          return (
            <OldDownloadElement
              key={index}
              downloadItem={{ download, index }}
            />
          );
        })}
        <h3>{getTextString(texts, "down8")}</h3>
        {genericDownloads.map((download: OldDownload, index: number) => {
          return (
            <OldDownloadElement
              key={index}
              downloadItem={{ download, index }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OldDownloads;
