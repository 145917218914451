import React from "react";
import Recoil from "recoil";
import { TextElement } from "../../models/textElementModel";
import {
  faqsListSelector,
  textsFaqPageSelector,
} from "../../utils/constants/recoilConstants";
import { Faq } from "../../models/faqModel";
import { getTextString } from "../../utils/functions";
import FaqElement from "./components/FaqElement";

const Faqs = () => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsFaqPageSelector);
  const faqsList = Recoil.useRecoilValue<Faq[]>(faqsListSelector);

  document.title = `${getTextString(texts, "title0")}: ${getTextString(
    texts,
    "title4"
  )}`;
  const faqsListHtmlElement = [...faqsList]
    .sort((elem1: Faq, elem2: Faq) => {
      if (elem1.id < elem2.id) {
        return -1;
      }
      if (elem1.id > elem2.id) {
        return 1;
      }
      return 0;
    })
    .map((faq: Faq, index: number) => {
      return <FaqElement key={index} faqItem={{ faq, index }} />;
    });

  return (
    <div id="contenuto">
      <div id="monoColonna">
        <h1>{getTextString(texts, "intro1")}</h1>
        <h2>{getTextString(texts, "faq1")}</h2>
        {faqsList.length > 0 && faqsListHtmlElement}
        {faqsList.length === 0 && <h3>No faqs posted</h3>}
      </div>
    </div>
  );
};

export default Faqs;
