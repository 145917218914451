import React from "react";
import Header from "./Header";
import Menu from "./Menu";
import Page from "./Page";

const Layout = () => {
  return (
    <>
      <Menu />
      <Header />
      <Page />
    </>
  );
};

export default Layout;
