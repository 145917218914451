import React from "react";
import Recoil from "recoil";
import { getTextString } from "../../../utils/functions";
import { TextElement } from "../../../models/textElementModel";
import { Faq } from "../../../models/faqModel";
import { textsFaqPageSelector } from "../../../utils/constants/recoilConstants";

const FaqElement = (props: { faqItem: { faq: Faq; index: number } }) => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsFaqPageSelector);
  const { faq, index } = props.faqItem;
  return (
    <dl key={index}>
      <dt>{`${getTextString(texts, "faq2")} ${index + 1}`}</dt>
      <dd className="id">{faq.question}</dd>
      <dt />
      <dd dangerouslySetInnerHTML={{ __html: faq.answer }} />
    </dl>
  );
};

export default FaqElement;
