import React from "react";
import Recoil from "recoil";
import { Link, useLocation } from "react-router-dom";
import {
  avviaLogon,
  hideProgressBar,
  showModalDialog,
} from "../legacyJs/base.js";
import { TextElement } from "../models/textElementModel";
import { getTextString } from "../utils/functions";
import {
  pageAfterLogin,
  textsLoginPageSelector,
  userAtom,
} from "../utils/constants/recoilConstants";
import { loginUser } from "../controllers/userController";

const Login = () => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsLoginPageSelector);
  document.title = `${getTextString(texts, "title0")}: ${getTextString(
    texts,
    "title8"
  )}`;

  const setAuthUser = Recoil.useSetRecoilState(userAtom);

  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const setNextPage = Recoil.useSetRecoilState(pageAfterLogin);

  const location = useLocation();
  const handleLogin = () => {
    avviaLogon(
      "loginForm",
      "loginNameNoInvio",
      "accediConInvio",
      getTextString(texts, "log8"),
      getTextString(texts, "log5"),
      getTextString(texts, "log6"),
      getTextString(texts, "log7")
    );
    if (username && password) {
      loginUser(username, password)
        .then((response) => {
          switch (response.status) {
            case 200:
              return response.json();
            default:
              return undefined;
          }
        })
        .then((response) => {
          if (response) {
            setAuthUser(response.jwtToken);
            window.sessionStorage.setItem("authToken", response.jwtToken);
            hideProgressBar();
          } else {
            hideProgressBar();
            showModalDialog(
              "Operazione non eseguita",
              "Nickname non esistente o password sbagliata",
              "Ok"
            );
          }
        });
    }
  };

  React.useEffect(() => {
    if (location.state) {
      // @ts-ignore
      const page: string = location.state.from;
      if (
        page === "/web/expert" ||
        page === "/web/downloads" ||
        page === "/web/old_downloads"
      ) {
        setNextPage(page);
      }
    }
  }, []);

  React.useEffect(() => {
    const handleEnterKeyPress = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleLogin();
      }
    };

    document.addEventListener("keydown", handleEnterKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [username, password]);

  return (
    <div id="contenuto">
      <div id="monoColonna">
        <h1>{getTextString(texts, "intro1")}</h1>
        <h2>{getTextString(texts, "log1")}</h2>
        <p>{getTextString(texts, "log2")}</p>
        <fieldset>
          <ol>
            <li>
              <label htmlFor="loginName">{getTextString(texts, "log3")}</label>
              <input
                id="loginNameNoInvio"
                name="nickname"
                type="text"
                onChange={(event) => setUsername(event.target.value)}
              />
            </li>
            <li>
              <label htmlFor="password">{getTextString(texts, "log4")}</label>
              <input
                id="accediConInvio"
                name="password"
                type="password"
                onChange={(event) => setPassword(event.target.value)}
              />
            </li>
          </ol>
          <Link to="#" className="bottone" id="accedi" onClick={handleLogin}>
            Accedi
          </Link>
        </fieldset>
        <p>
          {getTextString(texts, "log9")}{" "}
          <a href="/web/registration">{getTextString(texts, "log10")}</a>
        </p>
        <p>
          {getTextString(texts, "reg21")}{" "}
          <a href="/web/pass_recovery">{getTextString(texts, "reg22")}</a>
        </p>
      </div>
    </div>
  );
};

export default Login;
