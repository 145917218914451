import { Download, OldDownload } from "../models/downloadsModel";
import { hideProgressBar, showProgressBar } from "../legacyJs/base";

export const getDownloadList = async (): Promise<Response> => {
  return fetch("/api/downloads", {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const getOldDownloadList = async (): Promise<Response> => {
  return fetch("/api/old_downloads", {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
};

export const downloadFileFromURL = async (
  url: string,
  downloadElem: Download | OldDownload
) => {
  showProgressBar("Downloading...");
  const file = await fetch(url, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  });
  const fileBlog = await file.blob();
  const fileURL = URL.createObjectURL(fileBlog);

  const anchor = document.createElement("a");
  anchor.href = fileURL;
  if ("fileName" in downloadElem) {
    anchor.download = downloadElem.fileName;
  } else {
    anchor.download = downloadElem.name;
  }

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(fileURL);
  hideProgressBar();
};
