import Recoil from "recoil";
import {
  getBugText,
  getDownloadsText,
  getErrorText,
  getExpertText,
  getFaqText,
  getHomeTexts,
  getLoginText,
  getOldDownloadsText,
  getPassRecoveryText,
  getRegistrationText,
  getStandardText,
} from "../../controllers/textsController";
import { getBugsList } from "../../controllers/bugController";
import { getFaqsList } from "../../controllers/faqController";
import { Bug } from "../../models/bugsModel";
import { Faq } from "../../models/faqModel";
import { TextElement } from "../../models/textElementModel";
import {
  getDownloadList,
  getOldDownloadList,
} from "../../controllers/downloadsControllers";
import { Download, OldDownload } from "../../models/downloadsModel";
import { Question } from "../../models/questionModel";

export const userAtom = Recoil.atom({
  key: "authToken",
  default: undefined,
});

export const textsRegistrationPageSelector = Recoil.selector({
  key: "registrationText",
  get: async (): Promise<TextElement[]> => {
    return getRegistrationText()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const textsHomePageSelector = Recoil.selector({
  key: "homeTexts",
  get: async (): Promise<TextElement[]> => {
    return getHomeTexts()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const textsLoginPageSelector = Recoil.selector({
  key: "loginText",
  get: async (): Promise<TextElement[]> => {
    return getLoginText()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const textsFaqPageSelector = Recoil.selector({
  key: "faqTexts",
  get: async (): Promise<TextElement[]> => {
    return getFaqText()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const textsBugPageSelector = Recoil.selector({
  key: "bugTexts",
  get: async (): Promise<TextElement[]> => {
    return getBugText()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const textsErrorPageSelector = Recoil.selector({
  key: "errorTexts",
  get: async (): Promise<TextElement[]> => {
    return getErrorText()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const textsStandardsPageSelector = Recoil.selector({
  key: "standardTexts",
  get: async (): Promise<TextElement[]> => {
    return getStandardText()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const textsPassRecoveryPageSelector = Recoil.selector({
  key: "passRecoveryTexts",
  get: async (): Promise<TextElement[]> => {
    return getPassRecoveryText()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const textsDownloadPageSelector = Recoil.selector({
  key: "downloadTexts",
  get: async (): Promise<TextElement[]> => {
    return getDownloadsText()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const textsOldDownloadPageSelector = Recoil.selector({
  key: "oldDownloadTexts",
  get: async (): Promise<TextElement[]> => {
    return getOldDownloadsText()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const textsExpertPageSelector = Recoil.selector({
  key: "expertTexts",
  get: async (): Promise<TextElement[]> => {
    return getExpertText()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const bugsListSelector = Recoil.selector({
  key: "bugsList",
  get: async (): Promise<Bug[]> => {
    return getBugsList()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const faqsListSelector = Recoil.selector({
  key: "faqList",
  get: async (): Promise<Faq[]> => {
    return getFaqsList()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const downloadsListSelector = Recoil.selector({
  key: "downloadsList",
  get: async (): Promise<Download[]> => {
    return getDownloadList()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const oldDownloadsListSelector = Recoil.selector({
  key: "oldDownloadsList",
  get: async (): Promise<OldDownload[]> => {
    return getOldDownloadList()
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return undefined;
        }
      })
      .then((response) => {
        if (response) {
          return response;
        }
        return [];
      });
  },
});

export const lastFilling = Recoil.atom<{ id: number; orderNumber: number }[]>({
  key: "lastFilling",
  default: [],
});

export const questionsListAtom = Recoil.atom<Map<number, Question>>({
  key: "questionsList",
  default: new Map(),
});

export const differentPath = Recoil.atom<boolean>({
  key: "differentPath",
  default: false,
});

export const pageAfterLogin = Recoil.atom<string>({
  key: "pageAfterLogin",
  default: "/web",
});
