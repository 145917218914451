import React from "react";
import Recoil from "recoil";
import { Bug } from "../../../models/bugsModel";
import { getTextString } from "../../../utils/functions";
import { TextElement } from "../../../models/textElementModel";
import { textsBugPageSelector } from "../../../utils/constants/recoilConstants";

const BugElement = (props: { bugItem: { bug: Bug; index: number } }) => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsBugPageSelector);
  const { bug, index } = props.bugItem;
  return (
    <dl key={index}>
      <dt>{getTextString(texts, "bug6")}</dt>
      <dd className="id">{bug.descriptionId}</dd>
      <dt>{getTextString(texts, "bug7")}</dt>
      <dd>{bug.type}</dd>
      <dt>{getTextString(texts, "bug8")}</dt>
      <dd dangerouslySetInnerHTML={{ __html: bug.context }} />
      <dt>{getTextString(texts, "bug9")}</dt>
      <dd dangerouslySetInnerHTML={{ __html: bug.description }} />
      <dt>{getTextString(texts, "bug10")}</dt>
      <dd dangerouslySetInnerHTML={{ __html: bug.reproducibility }} />
      <dt>{getTextString(texts, "bug11")}</dt>
      <dd dangerouslySetInnerHTML={{ __html: bug.solution }} />
      <dt>{getTextString(texts, "bug12")}</dt>
      <dd dangerouslySetInnerHTML={{ __html: bug.version }} />
      <dt>{getTextString(texts, "bug13")}</dt>
      <dd dangerouslySetInnerHTML={{ __html: bug.state }} />
    </dl>
  );
};

export default BugElement;
