import React from "react";
import Recoil from "recoil";
import { Bug } from "../../models/bugsModel";
import { TextElement } from "../../models/textElementModel";
import { getTextString } from "../../utils/functions";
import {
  bugsListSelector,
  textsBugPageSelector,
} from "../../utils/constants/recoilConstants";
import { userLanguage } from "../../utils/constants/constants";
import BugElement from "./components/BugElement";

const Bugs = () => {
  const bugsList = Recoil.useRecoilValue<Bug[]>(bugsListSelector);
  const texts = Recoil.useRecoilValue<TextElement[]>(textsBugPageSelector);
  document.title = `${getTextString(texts, "title0")}: ${getTextString(
    texts,
    "title3"
  )}`;

  const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const getLastModifiedDate = (): string => {
    if (bugsList.length > 0) {
      const date: Date = new Date(
        bugsList[bugsList.length - 1].modificationDate
      );
      if (userLanguage === "text_en") {
        return `${date.getMonth().toString()}/${date
          .getDate()
          .toString()}/${date.getFullYear()}`;
      }
      return `${date.getDate().toString()} ${
        months[date.getMonth()]
      } ${date.getFullYear()}`;
    }
    return "";
  };

  const bugsListHtmlElement = [...bugsList].map((bug: Bug, index: number) => {
    return <BugElement key={index} bugItem={{ bug, index }} />;
  });

  return (
    <div id="contenuto">
      <div id="monoColonna">
        <h1>{getTextString(texts, "intro1")}</h1>
        <h2>{getTextString(texts, "bug1")}</h2>
        <div className="riquadroFiguraFlottante">
          <img
            className="figuraFlottante"
            src={require("../../assets/aboutBox.png")}
            alt=""
          />
        </div>
        <p> {getTextString(texts, "bug2")}</p>
        <p>{getTextString(texts, "bug3")}</p>
        <p>{getTextString(texts, "bug4")}</p>
        {bugsList.length > 0 && (
          <h3>{`${getTextString(texts, "bug5")} ${getLastModifiedDate()}`}</h3>
        )}
        {bugsList.length === 0 && <h3>No bugs posted</h3>}
        {bugsListHtmlElement}
      </div>
    </div>
  );
};

export default Bugs;
