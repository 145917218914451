import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Recoil from "recoil";
import Home from "./pages/Home";
import Bugs from "./pages/bugs/Bugs";
import Faq from "./pages/faqs/Faqs";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Layout from "./components/Layout";
import Downloads from "./pages/downloads/Downloads";
import { completaLayout } from "./legacyJs/base";
import { pageAfterLogin, userAtom } from "./utils/constants/recoilConstants";
import PassRecovery from "./pages/PassRecovery";
import OldDownloads from "./pages/oldDownloads/OldDownloads";
import Expert from "./pages/expert/Expert";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  const authSession = sessionStorage.getItem("authToken");
  const authAtom = Recoil.useRecoilValue(userAtom);
  const nextPage = Recoil.useRecoilValue(pageAfterLogin);

  return (
    <div className="App">
      <React.Suspense fallback={<div onLoad={() => completaLayout()} />}>
        <BrowserRouter>
          <Routes>
            <Route path="/web" element={<Layout />}>
              <Route path="" element={<Home />} />
              <Route path="bug" element={<Bugs />} />
              <Route path="faq" element={<Faq />} />
              <Route
                path="expert"
                element={
                  <PrivateRoute>
                    <Expert />
                  </PrivateRoute>
                }
              />
              <Route
                path="expert/:order/answer:answerId"
                element={
                  <PrivateRoute>
                    <Expert />
                  </PrivateRoute>
                }
              />
              <Route
                path="old_downloads"
                element={
                  <PrivateRoute>
                    <OldDownloads />
                  </PrivateRoute>
                }
              />
              <Route
                path="downloads"
                element={
                  <PrivateRoute>
                    <Downloads />
                  </PrivateRoute>
                }
              />
              <Route
                path="pass_recovery"
                element={
                  authAtom || authSession ? (
                    <Navigate to="/web" />
                  ) : (
                    <PassRecovery />
                  )
                }
              />
              <Route
                path="login"
                element={
                  authAtom || authSession ? (
                    <Navigate to={nextPage} replace={true} />
                  ) : (
                    <Login />
                  )
                }
              />
              <Route
                path="registration"
                element={
                  authAtom || authSession ? (
                    <Navigate to="/web" />
                  ) : (
                    <Registration />
                  )
                }
              />
            </Route>
            <Route path="*" element={<Navigate to="/web" />} />
          </Routes>
        </BrowserRouter>
      </React.Suspense>
    </div>
  );
}

export default App;
