import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Recoil from "recoil";
import { getTextString } from "../../utils/functions";
import { TextElement } from "../../models/textElementModel";
import {
  differentPath,
  lastFilling,
  questionsListAtom,
  textsExpertPageSelector,
} from "../../utils/constants/recoilConstants";
import { Question } from "../../models/questionModel";
import {
  getFirstQuestion,
  getLastFilling,
  getNextQuestionByAnswerId,
  sendAnswersHistory,
} from "../../controllers/questionsController";
import {
  hideProgressBar,
  showModalDialog,
  showProgressBar,
} from "../../legacyJs/base";
import ExpertTitle from "./components/ExpertTitle";
import OpenAnswer from "./components/OpenAnswer";
import QuestionPanel from "./components/QuestionPanel";

const Expert = () => {
  const texts = Recoil.useRecoilValue<TextElement[]>(textsExpertPageSelector);
  document.title = `${getTextString(texts, "title0")}: ${getTextString(
    texts,
    "title7"
  )}`;

  const setQuestionsList = Recoil.useSetRecoilState(questionsListAtom);
  const questionsDictionary: Map<number, Question> = new Map<number, Question>(
    Recoil.useRecoilValue(questionsListAtom)
  );

  const setAnswersLastFilling = Recoil.useSetRecoilState(lastFilling);
  const answersLastFilling: { id: number; orderNumber: number }[] = Array.from(
    Recoil.useRecoilValue(lastFilling)
  );
  const setIsDifferentPath = Recoil.useSetRecoilState(differentPath);
  const isDifferentPath: boolean = Recoil.useRecoilValue(differentPath);

  const [currentQuestion, setCurrentQuestion] = React.useState<Question>();

  const navigate = useNavigate();

  const { order, answerId } = useParams();
  const hasPreviousQuestion: boolean = answerId !== undefined;

  const getNextQuestion = () => {
    // Check whether the current application has already been seen
    const questionAlreadySeen: Question | undefined = questionsDictionary.get(
      answerId ? Number(answerId) : 0
    );
    if (!questionAlreadySeen) {
      // Need the first question
      if (answerId === undefined) {
        getFirstQuestion()
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              default:
                return undefined;
            }
          })
          .then((question: Question) => {
            if (question) {
              setCurrentQuestion(question);
              questionsDictionary.set(0, question);
              setQuestionsList(questionsDictionary);
            }
          });
      } else {
        getNextQuestionByAnswerId(Number(answerId))
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              default:
                return undefined;
            }
          })
          .then((question: Question) => {
            if (question) {
              setCurrentQuestion(question);
              if (
                !isDifferentPath &&
                answersLastFilling &&
                answersLastFilling.length > 0 &&
                answersLastFilling[Number(order) - 1].id === Number(answerId)
              ) {
                // if the user is following the same path of the last filling
                questionsDictionary.set(Number(answerId), question);
                setQuestionsList(questionsDictionary);
              } else {
                if (!isDifferentPath) {
                  setIsDifferentPath(true);
                }
                const keys: number[] = Array.from(questionsDictionary.keys());
                const keysToDelete: number[] = keys.slice(
                  Number(order),
                  keys.length
                );
                keysToDelete.forEach((key) => {
                  questionsDictionary.delete(key);
                });
                questionsDictionary.set(Number(answerId), question);
                setQuestionsList(questionsDictionary);
              }
            }
          });
      }
    } else {
      setCurrentQuestion(questionAlreadySeen);
    }
  };

  React.useEffect(() => {
    if (questionsDictionary.size >= Number(order || 0)) {
      if (answersLastFilling && answersLastFilling.length === 0) {
        getLastFilling()
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              default:
                return undefined;
            }
          })
          .then((response) => {
            if (response) {
              setAnswersLastFilling(response);
            }
          });
      }
      getNextQuestion();
    } else {
      navigate("/web/expert");
    }
  }, [answerId]);

  const previousQuestion = () => {
    const orderNumber: number = Number(order) - 1;
    if (orderNumber > 0) {
      const keys: number[] = Array.from(questionsDictionary.keys());
      const keysToDelete: number[] = keys.slice(Number(order) + 1, keys.length);

      keysToDelete.forEach((key) => {
        questionsDictionary.delete(key);
      });
      setQuestionsList(questionsDictionary);
    }
    navigate(-1);
  };

  const sendHistory = (openAnswer?: string) => {
    const answerList: number[] = Array.from(questionsDictionary.keys());
    showProgressBar(getTextString(texts, "se13"));
    sendAnswersHistory(answerList.slice(1, answerList.length), openAnswer)
      .then((response) => {
        switch (response.status) {
          case 200:
            return response;
          default:
            return undefined;
        }
      })
      .then((response) => {
        hideProgressBar();
        if (response) {
          navigate("/web");
        } else {
          showModalDialog(
            getTextString(texts, "error1"),
            getTextString(texts, "error1"),
            "Ok"
          );
        }
      });
  };

  return (
    <div id="contenuto">
      <div id="monoColonna">
        <h1>{getTextString(texts, "intro1")}</h1>
        {currentQuestion && (
          <>
            <ExpertTitle currentQuestion={currentQuestion} />
            <div id="panelDomanda">
              {!currentQuestion.compResult && currentQuestion.conclusive ? (
                <OpenAnswer sendHistory={sendHistory} />
              ) : (
                <QuestionPanel
                  currentQuestion={currentQuestion}
                  sendHistory={sendHistory}
                />
              )}
            </div>
            {hasPreviousQuestion && (
              <Link
                className="bottoneBack"
                to=""
                onClick={() => {
                  previousQuestion();
                }}
              >
                {getTextString(texts, "se9")}
              </Link>
            )}
            {hasPreviousQuestion &&
              !(currentQuestion.conclusive && currentQuestion.compResult) && (
                <p />
              )}
            {currentQuestion.conclusive && currentQuestion.compResult && (
              <p>{getTextString(texts, "se10")}</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Expert;
