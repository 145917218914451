import React from "react";
import { Link, useParams } from "react-router-dom";
import Recoil from "recoil";
import { Answer } from "../../../models/answerModel";
import {
  differentPath,
  lastFilling,
  questionsListAtom,
} from "../../../utils/constants/recoilConstants";
import { Question } from "../../../models/questionModel";

const AnswerElement = (props: { answer: Answer; index: number }) => {
  const { answer, index } = props;
  const { order } = useParams();
  const questionsDictionary: Map<number, Question> = new Map<number, Question>(
    Recoil.useRecoilValue(questionsListAtom)
  );
  const answersAlreadyDoneList: { id: number; orderNumber: number }[] =
    Array.from(Recoil.useRecoilValue(lastFilling));

  const [isSelected, setIsSelected] = React.useState<boolean>(false);

  const isDifferentPath: boolean = Recoil.useRecoilValue(differentPath);

  React.useEffect(() => {
    if (!isDifferentPath) {
      if (answersAlreadyDoneList && answersAlreadyDoneList.length > 0) {
        if ((order ? Number(order) : 0) === answersAlreadyDoneList.length) {
          setIsSelected(false);
        } else {
          setIsSelected(
            answersAlreadyDoneList[order ? Number(order) : 0].id === answer.id
          );
        }
      }
    } else {
      setIsSelected(questionsDictionary.has(answer.id));
    }
  }, [answersAlreadyDoneList]);

  return (
    answersAlreadyDoneList && (
      <Link
        className={isSelected ? "scelta" : ""}
        key={index}
        to={`/web/expert/${
          !order ? 1 : Number(order) + 1
        }/answer${answer.id.toString()}`}
      >
        {answer.text}
      </Link>
    )
  );
};

export default AnswerElement;
