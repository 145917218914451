import React from "react";
import { Link } from "react-router-dom";
import Recoil from "recoil";
import { Question } from "../../../models/questionModel";
import { getTextString } from "../../../utils/functions";
import { TextElement } from "../../../models/textElementModel";
import { textsExpertPageSelector } from "../../../utils/constants/recoilConstants";
import { hideProgressBar, showProgressBar } from "../../../legacyJs/base";
import AnswerElement from "./AnswerElement";

const QuestionPanel = (props: {
  currentQuestion: Question;
  sendHistory: (openAnswer?: string) => void;
}) => {
  const { currentQuestion, sendHistory } = props;
  const texts = Recoil.useRecoilValue<TextElement[]>(textsExpertPageSelector);
  const answerListHtml = currentQuestion?.answers?.map((answer, index) => (
    <AnswerElement key={index} answer={answer} index={index} />
  ));

  React.useEffect(() => {
    showProgressBar("Loading page...");
    hideProgressBar();
  }, [currentQuestion]);

  return (
    <>
      {currentQuestion.imageName ? (
        <div className="riquadroFiguraFlottante">
          <img
            id={currentQuestion.imageName}
            src={`/api/images/${currentQuestion.imageName}`}
            alt=""
            className="figuraFlottante"
          />
        </div>
      ) : (
        <></>
      )}
      <p
        className="domanda"
        dangerouslySetInnerHTML={{ __html: currentQuestion.text }}
      />
      {currentQuestion.conclusive ? (
        <Link
          to=""
          className="bottone"
          onClick={() => {
            sendHistory();
          }}
        >
          {getTextString(texts, "se8")}
        </Link>
      ) : (
        <div className="opzioni">{answerListHtml}</div>
      )}
    </>
  );
};

export default QuestionPanel;
